@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'iranyekan';
  src: url("../public/assets/fonts/iranyekanwebregularfanum.eot") format('embedded-opentype'),
  url("../public/assets/fonts/iranyekanwebregularfanum.woff") format('woff'),
  url("../public/assets/fonts/iranyekanwebregularfanum.ttf") format('truetype');
}

::-webkit-scrollbar{
  width: 14px;
}

::-webkit-scrollbar-thumb{
  background-color: tomato;
  border-radius: 10px;
}

html, body{
  overflow-x: hidden;
  scroll-behavior: smooth;
}
